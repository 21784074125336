/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-01-25",
    versionChannel: "nightly",
    buildDate: "2024-01-25T00:15:18.655Z",
    commitHash: "917c1c08f73b68d6ab7c48736060960b095d4b75",
};
